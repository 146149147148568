<template>
  <iq-main>
    <div class="container">
      <div class="wrapper">
        <iq-confetti />

        <div class="md-title">Willkommen {{ full_name }}!</div>
        <div class="md-subheading">
          Bitte bestätigen Sie die E-Mail, welche wir Ihnen zukommen haben
          lassen.
        </div>
      </div>
    </div>
  </iq-main>
</template>

<script>
import IqConfetti from "@/components/utilities/iq-confetti";
const Settings = require("@/config");

export default {
  name: "Greeting",
  components: { IqConfetti },
  data() {
    return {
      full_name: null,
      app_name: Settings.APP_NAME,
    };
  },
  created() {
    // Insert space at the front!
    this.full_name = ` ${this.$route.query.full_name}`;
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > .wrapper {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .md-title {
      font-weight: bold;
    }
  }
}
</style>
